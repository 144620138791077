<template>
  <div>

    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <b-card>
        <div class="d-flex  align-items-center justify-content-between">
          <h3 class="">
            All Jobs
          </h3>
          <b-form-group
            label="View as"
            label-for="btn-radios-1"
            label-size="sm"
          >
            <b-form-radio-group
              id="btn-radios-1"
              v-model="view"
              :options="[
                { text: 'Grid', value: 'grid' },
                { text: 'List', value: 'list' },
              ]"
              button-variant="light"
              buttons
              size="sm"
            />
          </b-form-group>

        </div>
        <hr>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search meeting"
              type="text"
            />
            <b-button
              href="/startup-portal/job/add"
              variant="primary mr-1"
            >
              <feather-icon icon="PlusIcon" />
              Add Job
            </b-button>
          </b-form-group>
        </div>
        <!-- cards -->
        <section
          v-if="view === 'grid'"
          class="grid-view mt-1"
        >
          <b-row>
            <b-col
              v-for="(program, i) in rows"
              :key="i"
              :title="program.title"
              md="4"
              style="margin-bottom: 10px"
            >
              <b-card
                :key="i"
                img-src="https://picsum.photos/600/300/?image=25"
                img-alt="Image"
                img-top
                class="ecommerce-card"
                tag="article"
                style="border-radius: 10px; margin-bottom: 0; height: 100%;  position: relative;"
              >
                <b-badge
                  style="position: absolute; top: 10px; right: 10px;color: black;"
                  variant="light"
                >
                  {{ program.type }}
                </b-badge>
                <!-- <b-badge
                  style="position: absolute; top: 40px; right: 10px;color: black;"
                  variant="light"
                >
                  Free
                </b-badge> -->
                <b-badge
                  style="position: absolute; top: 10px; left: 10px;color: black;"
                  variant="light"
                >
                  J
                </b-badge>
                <!-- <div class="d-flex align-items-center ">
                  <b-img
                    rounded
                    alt="Rounded image"
                    src="https://shorturl.at/kxFUX"
                    style="height: 30px; width: 30px; margin-right: 10px;"
                  />
                  <b-card-text> <strong>Startup Name</strong></b-card-text>
                </div> -->
                <div class="mt-2 mb-1">
                  <b-card-title>
                    {{ program.title }}
                  </b-card-title>
                  <div class="d-flex align-items-baseline   justify-content-between">
                    <b-card-text><strong>
                      <feather-icon icon="CalendarIcon" />
                      {{ beginDate(program.begin_date) }}</strong></b-card-text>
                    <b-badge class="badge badge-light-success">
                      {{ program.delivery_mode }}
                    </b-badge>
                  </div>
                  <b-card-text>
                    {{ program.description }}
                  </b-card-text>
                </div>
                <div class="item-options text-center d-flex justify-content-between ">
                  <b-link
                    class="btn btn-primary btn-cart"
                    size="sm"
                    :to="{name: 'job-application-manager', params: {id: program.id}}"
                  >
                    <span>Applications</span>
                  </b-link>
                  <div>

                    <b-button
                      variant="flat-secondary"
                      @click="selectedIndex=i; $bvModal.show('view-details-modal')"
                    >
                      Details
                    </b-button>
                    <!--                    job delete button-->
                    <b-button
                      class="text-capitalize ml-1"
                      size="sm"
                      variant="outline-danger"
                      @click="showDeleteModal(program.id, program.organization_id_id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                      />
                    </b-button>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between align-items-center  flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLengthGrid"
                :options="['9', '27', '81']"
                class="mx-1"
                @input="updatePageLength"
              />
              <span class="text-nowrap"> of {{ rows.length }} entries </span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :value="1"
                :per-page="pageLengthGrid"
                :total-rows="totalItems"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value) => onPageChange(value)"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </section>
        <!-- table -->

        <vue-good-table
          v-if="view === 'list'"
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'title'">
              {{ props.row.title }}
              <b-button
                size="sm"
                variant="flat-primary"
                @click="selectedIndex = props.row.originalIndex; $bvModal.show('view-details-modal')"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="d-flex"
            >
              <b-link
                class="btn btn-primary btn-cart"
                size="sm"
                :to="{name: 'job-application-manager', params: {id: props.row.id}}"
              >
                <span>Applications</span>
              </b-link>
              <!--Delete job button-->
              <b-button
                class="text-capitalize ml-1"
                size="sm"
                variant="outline-danger"
                @click="showDeleteModal(props.row.id, props.row.organization_id_id)"
              ><feather-icon
                icon="TrashIcon"
              /></b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
    <!--    Delete job model-->
    <b-modal
      id="deleteModal"
      button-size="sm"
      centered
      no-close-on-backdrop
      ok-title="Yes, Delete"
      ok-variant="danger"
      size="sm"
      title="Confirm Delete"
      @ok="deleteJob"
    >
      <h6>Are you sure you want to delete this Job?</h6>
    </b-modal>
    <b-modal
      id="scheduleEventModal"
      ok-title="Invite"
      size="lg"
      :title="'Invite '+this.$route.params.sname+' to Event'"
      @ok="addEvent"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            class="mb-2"
            label="Select Event"
            label-for="Event"
          >
            <v-select
              id="Event"
              v-model="type"
              :options="meetingTypeOptions"
              placeholder="Select Event"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            class="mb-2"
            label="Message"
            label-for="message"
          >
            <b-form-textarea
              id="message"
              v-model="message"
              placeholder="Enter your message here"
              rows="5"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-if="rows.length"
      id="updateLinks"
      ok-title="Update"
      title="Update Event Links"
      @ok="updateLinks"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Event Link"
            label-for="updateEventLink"
          >
            <b-form-input
              id="updateEventLink"
              v-model="rows[selectedRow].meeting_link"
              placeholder="Event Link"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Recording Link"
            label-for="updateRecordingLink"
          >
            <b-form-input
              id="updateRecordingLink"
              v-model="rows[selectedRow].recording_link"
              placeholder="Recording Link"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="CopyModal"
      title="Copy Link"
      ok-title="Copy"
    >
      <b-card-title>form title</b-card-title>
      <b-input-group>
        <b-form-input
          :id="`application-link`"
          size="sm"
          :value="`hello world`"
          @keydown="event => event.preventDefault()"
          @focus="event => {event.target.select()}"
        />
        <template #append>
          <!-- Copy to Clipboard button with Copy icon at start -->
          <b-button
            size="sm"
            variant="outline-primary"
            @click="copyToClipboard('hello world')"
          >
            <feather-icon icon="CopyIcon" />
            Copy
          </b-button>
        </template>
      </b-input-group>
    </b-modal>
    <b-modal
      id="view-details-modal"
      :title="jobDetails.title"
      hide-footer
      size="lg"
    >
      <b-table
        stacked
        bordered
        :items="[{
          'Job Title': jobDetails.title,
          'Job Type ': jobDetails.type,
          'Job Description': jobDetails.description,
          'Expected Date of Joining': `${new Date(jobDetails.begin_date)
            .toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              timeZone: 'UTC',
              hour12: false,
            })}`,
          'Type of Remuneration': jobDetails.prize && jobDetails.prize.length > 0 ? jobDetails.prize.join(', ') : '-',
        }]"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      id="addParticipants"
      ok-only
      ok-title="Update"
      title="Add Participants"
      @ok="updateParticipants"
    >
      <b-form-group
        label="Select Participant"
        label-for="selectParticipant"
      >
        <treeselect
          id="selectParticipant"
          v-model="participants"
          :options="associations"
          value-consists-of="LEAF_PRIORITY"
          show-count
          show-count-of="LEAF_DESCENDANTS"
          multiple
          placeholder="Select Participant"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>

import {
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BLink,
  BModal,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import Treeselect from '@riophae/vue-treeselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BFormRadioGroup,
    BBadge,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BCardText,
    BLink,
    BCardTitle,
    BCard,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BFormTextarea,
    vSelect,
    Treeselect,
    BTable,
  },
  data() {
    return {
      title: '',
      pageLengthGrid: 9,
      view: 'grid',
      type: '',
      beginTime: '',
      endTime: '',
      meetingLink: '',
      message: '',
      operationsId: null,
      operations: [
        {
          id: '1',
          label: 'Milestones',
          children: [],
        },
        {
          id: '2',
          label: 'Requests',
          children: [],
        },
        {
          id: '3',
          label: 'Mentoring',
          children: [],
        },
        {
          id: '4',
          label: 'Partner Services',
          children: [],
        },
        {
          id: '5',
          label: 'General',
          children: [{
            id: '',
            label: 'General',
          }],
        },
      ],
      status: '',
      mutationLoading: false,
      meetingTypeOptions: [],
      selectedIndex: 0,
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Mode',
          field: 'mode',
        },
        {
          label: 'Type of Remuneration',
          field: 'remuneration',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      selectedOperation: null,
      selectedRowId: 0,
      orgId: 0,
      associations: [
        {
          id: '0',
          label: 'Startups',
          children: [
            {
              id: '0-0',
              label: 'Incubatee',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-1',
              label: 'Graduate',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-2',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-3',
              label: 'Pre-Incubatee',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '1',
          label: 'Students',
          children: [
            {
              id: '1-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '1-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '2',
          label: 'Mentors',
          children: [
            {
              id: '2-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '2-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '3',
          label: 'Partners',
          children: [
            {
              id: '3-0',
              label: 'In-House',
              children: [],
              isDisabled: true,
            },
            {
              id: '3-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
      ],
    }
  },
  computed: {
    jobDetails() {
      return this.rows[this.selectedIndex] || {}
    },
    totalItems() {
      return this.rows.length
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageLength)
    },
    participants: {
      get() {
        return []
      },
      set(e) {
        const participants = []
        // eslint-disable-next-line no-return-assign
        e.forEach((v, i) => participants[i] = {
          user_association_id: v,
          schedule_id_id: this.rows[this.selectedRow].id,
        })
        this.rows[this.selectedRow].programs_meetparticipanttables = participants
      },
    },
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
        .filter(obj => obj.role === 'superadmin')
      return arr.map(e => e.organization_id)
        .filter(obj => obj) // Remove nulls
    },
    milestoneId() {
      if (!this.operationsId) return null
      return this.operationsId.split('-')[0] === 'milestone' ? this.operationsId.split('-')[1] : null
    },
    requestId() {
      if (!this.operationsId) return null
      return this.operationsId.split('-')[0] === 'request' ? this.operationsId.split('-')[1] : null
    },
  },
  methods: {
    // Show delete modal
    showDeleteModal(programId, organizationId) {
      this.selectedRowId = programId
      this.orgId = organizationId
      this.$bvModal.show('deleteModal')
    },
    // Delete job function
    deleteJob() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
           mutation MyMutation {
      update_programs_basicinfo(where: {id: {_eq: ${this.selectedRowId}}, organization_id_id: {_in: [${this.orgId}]}}, _set: {is_deleted: true}) {
        affected_rows
      }
    }`,
        update: () => {
          this.showAlert('Job deleted sucessfully', 'success')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    deleteModule() {
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this module?',
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete File',
        },
      )
    },
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.$bvToast.toast('Application link copied to clipboard', {
            title: 'Success',
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
          })
        }).catch(() => {
        })
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = text
        textarea.style.position = 'fixed' // ensure the textarea is rendered offscreen
        document.body.appendChild(textarea)
        textarea.focus()
        textarea.select()
        try {
          document.execCommand('copy') // execute the copy command
        } catch (err) {
          // failed to copy
        } finally {
          document.body.removeChild(textarea) // remove the textarea from the DOM
        }
      }
    },
    onPageChange(value) {
      this.currentPage = value
    },
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    updateView(view) {
      this.view = view
    },

    toTimestamp(strDate) {
      const datum = Date.parse(strDate)
      return datum / 1000
    },
    addEvent() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_programs_scheduletable_one(object: {begin_time: "${this.beginTime}", end_time: "${this.endTime}", meeting_link: "${this.meetingLink}", milestone_id: ${this.milestoneId}, request_id: ${this.requestId}, status: "scheduled", title: "${this.title}", type: "${this.type}", programs_meetparticipanttables: {data: {user_association_id: 84}}}) {
            id
          }
        }`,
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'EMeet created successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create EMeet',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateLinks() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_programs_scheduletable_by_pk(pk_columns: {id: ${this.rows[this.selectedRow].id} }, _set: {recording_link: "${this.rows[this.selectedRow].recording_link}", meeting_link: "${this.rows[this.selectedRow].meeting_link}"}) {
            id
          }
        }`,
        update: (store, { data: { update_programs_scheduletable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_programs_scheduletable_by_pk.id ? 'Updated successfully' : 'Failed to update',
              icon: update_programs_scheduletable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_programs_scheduletable_by_pk.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateParticipants() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_meetparticipanttable_insert_input!]!) {
          delete_programs_meetparticipanttable(where: {schedule_id_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_meetparticipanttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables: {
          objects: this.rows[this.selectedRow].programs_meetparticipanttables,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
          query MyQuery {
          programs_basicinfo(order_by: {date_created: desc}, where: {organization_id_id: {_in: [${getUserData().associatedOrgDetails[0].organization_id}]}, is_job: {_eq: true}}) {
            id,
            title,
            status,
            organization_id_id,
            programs_applicantstables_aggregate {
              aggregate {
                count
              }
            },
            type,
            begin_date,
            capacity,
            city,
            customer_type,
            delivery_mode,
            description,
            duration,
            end_date,
            focus_type,
            offerings_type,
            ideal_lifecycle_stage,
            industry,
            image,
            is_public,
            offerings_type,
            state
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    operations: {
      query() {
        return gql`
        query startup_emeet_operations {
          requesttable(where: { _or: [
    { assignee_id: {_eq: ${this.$route.params.id} }},
    { requester_id: {_eq: ${this.$route.params.id} }}]}, order_by: {created_at: desc}) {
            requesttable_id
            title
            type
          }
          programs_progressstagetable(where: { startup_association_id: {_eq: ${this.$route.params.id}}} ,order_by: {stage_number: desc}) {
            id
            title
          }
        }`
      },
      update(data) {
        const { operations } = this
        const types = ['milestones', 'request', 'mentor', 'partner']
        data.programs_progressstagetable.forEach(e => {
          operations[0].children.push({
            label: e.title,
            id: `milestone-${e.id}`,
          })
        })
        data.requesttable.forEach(e => {
          operations[types[e.type]].children.push({
            label: e.title,
            id: `request-${e.requesttable_id}`,
          })
        })
        return operations
      },
    },
    associations: {
      query() {
        return gql`
        query startup_associations($incubator_id: Int!) {
          users_associationtable(where: {organization_id_id: {_in: [${this.userOrgs}]}}) {
            id
            role
            status
            users_customuser {
              full_name
            }
            users_organizationtable {
              title
            }
          }
        }`
      },
      update(data) {
        const { associations } = this
        data.users_associationtable.forEach(e => {
          if (e.role === 'partner') {
            associations[3].children[e.status === 'accept' ? 0 : 1].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[3].children[e.status === 'accept' ? 0 : 1].isDisabled = false
          } else if (e.role === 'mentor') {
            associations[2].children[e.status === 'accept' ? 0 : 1].children.push({
              label: e.users_customuser.full_name,
              id: e.id,
            })
            associations[2].children[e.status === 'accept' ? 0 : 1].isDisabled = false
          } else if (e.role === 'student') {
            associations[1].children[e.status === 'accept' ? 0 : 1].children.push({
              label: e.users_customuser.full_name,
              id: e.id,
            })
            associations[1].children[e.status === 'accept' ? 0 : 1].isDisabled = false
          } else if (e.role === 'incubatee') {
            associations[0].children[0].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[0].children[0].isDisabled = false
          } else if (e.role === 'graduate') {
            associations[0].children[1].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[0].children[1].isDisabled = false
          } else if (e.role === 'startup') {
            associations[0].children[2].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[0].children[2].isDisabled = false
          } else if (e.role === 'pre-incubatee') {
            associations[0].children[3].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[0].children[3].isDisabled = false
          }
        })
        return associations
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
